import { useState, useEffect } from "react"
import axios from "../../plugins/axios";
import Loader from "../../components/UI/Loader"
import NotAuthenticated from "src/components/layout/NotAuthenticated";

import "./WarehousePage.css"

export default function WarehousePage(){
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false)
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));


    useEffect(()=>{
        const fetchData = async () =>{
            try{
                setLoading(true);
                const response = await axios.get('/product/all') 
                setProducts(response.data)
            }
            catch(err){
                console.log(err);
                alert(err)
            }finally{
                    setLoading(false)
            }   
        }
        fetchData()
    }, [])

    if(!currentUser){
        return <NotAuthenticated />
    }
    if(loading){
        return <Loader />
    }

    return(
        <div className="warehousePage">
                <div className="container">
                    <div className="warehouse_header">
                        <h1>Warehouse Management</h1>
                        <a href="/warehouse/create" className="warehouse_page_create">Create Product</a>
                    </div>
                    <div className="warehouse_products_list">
                        {products.map(product=>{
                            return(
                                <div className="warehouse_card" key={product._id}>
                                    <h2 className="warehouse_card_title">{product.title}</h2>
                                    <p className="warehouse_card_measurement"><strong>Quantity: </strong>{`${product.quantity} ${product.measurementUnit}`}</p>
                                    <button className="warehouse_card_btn" onClick={()=>window.location.href=`/warehouse/${product._id}`}>See Logs</button>
                                </div>
                            )
                        })}
                    </div>
                </div>
        </div>
    )
}