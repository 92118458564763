import React, { useEffect, useState } from "react"
import { useParams } from 'react-router-dom';
import "./Permit.css"
import Loader from "../../components/UI/Loader"
import axios from "../../plugins/axios";
import {v4} from "uuid"

import MechanicalPermit from "../../assets/mechanical_permit.svg"
import ElectricalPermit from "../../assets/electrical_permit.svg"
import HotWorkPermit from "../../assets/hotwork_permit.svg"
import LiftingPermit from "../../assets/lifting_permit.svg"
import CleaningPermit from "../../assets/cleaning_permit.svg"


export default function Permit(){
    const {id} = useParams()
    const [permit, setPermit] = useState([])
    const [signatures, setSignatures] = useState([])
    const [equipments, setEquipments] = useState([])
    const [loading, setLoading] = useState(false) 
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    console.log("current user: " + currentUser.firstName)
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const permitResponse = await axios.get(`/permit/${id}`);
                console.log(permitResponse.data)
                setPermit(...permit, permitResponse.data[0])
                setEquipments(...equipments, permitResponse.data[0].equipments)
                setSignatures(...signatures, permitResponse.data[0].signatures) 
                setLoading(false)
            } catch (error) {
                console.error('Error fetching permit:', error);
            }
        };
        fetchData();
    }, []);

    const signPermit = async (permitId, signatureTitle, signer, newState) => {
        const requestData = {
            signature: {
                id: v4(),
                title: signatureTitle,
                date: new Date(),
                signer: signer
            },
            permitId: permitId,
            state: newState,
        };
        
        console.log(requestData);
        
        try {
            await axios.put('/permit/sign', requestData)
                .then(() => {
                    alert("Signed");
                    window.location.href = '/permit';
                })
                .catch(error => {
                    console.error('Error:', error.response.data); // Log the response data for better understanding of the error
                    // Handle the error here, e.g., display an error message to the user
                });
        } catch (error) {
            console.error('Error:', error); // Log any general JavaScript errors
        }
    }

    const deletePermit = async () =>{
        try{
            const response = await axios.delete('/permit/delete', {
                data: { id: id }
              })
            .then(response=>{
                alert("Deleted");
                console.log(response);
                window.location.href = '/permit';
            })
        }
        catch(err){
            console.log(err)
            alert("Error")
        }
    }

    //Signatures list
    const signInitiatePermit = () => {
        signPermit(id, "Initiate Permit", `${currentUser.firstName} ${currentUser.lastName}`, "Awaiting Approval")
    }

    const signActivatePermit = () => {
        signPermit(id, "Activate Permit", currentUser._id, "Live")
    }

    const signSuspentPermit = () => {
        signPermit(id, "Suspend Permit", currentUser._id, "Suspended")
    }

    const signReactivatePermit = () => {
        signPermit(id, "Reactivate Permit", currentUser._id, "Live")
    }

    const signClosePermit = () => {
        signPermit(id, "Reactivate Permit", currentUser._id, "Closed")
    }

    const signRevertPermit = () => {
        signPermit(id, "Revert Permit", currentUser._id, "Draft")
    }

    const signTestPermit = () =>{
        console.log(currentUser._id)
    }


    if (!permit) {
        return (
            <div className="loader_wrapper" data-aos="fade-up">
             <Loader />
            </div>
        );
    }
    if (permit){
        return(
            <div className="permit">
                <div className="container">
                    <div className="permit_card">
                    <h1>{permit.title}</h1>
                    <div className="permit_image_container">
                        {permit.type == "Mechanical" ? <img src={MechanicalPermit} alt="permit icon"  className="permit_image"/> : null}
                        {permit.type == "Electrical" ? <img src={ElectricalPermit} alt="permit icon"  className="permit_image"/> : null}
                        {permit.type == "Hot Work" ? <img src={HotWorkPermit} alt="permit icon" className="permit_image" /> : null}
                        {permit.type == "Weigh Lifting" ? <img src={LiftingPermit} alt="permit icon"  className="permit_image"/> : null}
                        {permit.type == "Cleaning" ? <img src={CleaningPermit} alt="permit icon"  className="permit_image"/> : null}
                    </div>
                    <p className="permit_state"><strong>{permit.state}</strong></p>
                    <p>Type: {permit.type}</p>
                    <p>Author: <a href={`/hr/user/${permit.username}`}>{`@${permit.username}` || 'No User'}</a></p>
                    <p>Description: {permit.description}</p>
                    <p>Location: {permit.location}</p>
                    <p>Start Date: {permit.startDate}</p>
                    <p>End Date: {permit.endDate}</p>
                    <p>Number of Persons: {permit.numberOfPersons}</p>
                    <h3>Equipments</h3>
                        {equipments.map((equipment, index) => (
                            <div key={equipment._id} className="permit_list_items">
                                <p><strong>{`${index + 1}. ${equipment.title}`}</strong></p>
                                <p>Description: {equipment.description}</p>
                                {/* <p>Quantity: {equipment.quantity}</p><br/> */}
                            </div>
                        ))}
                        <h3>Signatures Trial</h3>
                        {signatures.map((signature, index) => (
                            <div key={signature.id} className="permit_list_items">
                                <p><strong>{signature.title}</strong></p>
                                <p>{signature.date}</p>
                            </div>
                        ))}
                        <h3>Actions</h3>
                        <div className="permit_actions">
                            {/* <button onClick={signTestPermit} className="permit_action_item_sign">Test Signature</button> */}
                            {/* Signatures -- Draft */}
                            {permit.state === "Draft" ? (
                                <div>
                                        <button onClick={signInitiatePermit} className="permit_action_item_sign">Initiate Permit</button>
                                    </div>
                                ) : null}

                                {/* Signatures -- Awaiting Approval */}
                                {permit.state === "Awaiting Approval" ? (
                                    <div>
                                        <button onClick={signActivatePermit} className="permit_action_item_sign">Activate Permit</button>
                                        <button onClick={signRevertPermit} className="permit_action_item_sign">Revert to Draft</button>
                                    </div>
                                ) : null}

                                {/* Signatures -- Live */}
                                {permit.state === "Live" ? (
                                    <div>
                                        <button onClick={signClosePermit} className="permit_action_item_sign">Close Permit</button>
                                        <button onClick={signSuspentPermit} className="permit_action_item_sign ">Suspend Permit</button>
                                        <button onClick={signRevertPermit} className="permit_action_item_sign">Revert to Draft</button>
                                    </div>
                                ) : null}
                                <button onClick={deletePermit} className="permit_action_item_delete">Delete</button>
                                <a href="/permit" className="create_permit_close">Close</a>
                        </div>
                    </div>

                       
            </div>
        </div>                      
        )
    }

    

}