import "./HrPageItem.css"

export default function PermitPageItem(props){
    
    return (
        <div className="permit_list_item" data-aos="fade-up" onClick={()=>{window.location.href = `/hr/user/${props.user.username}`}}>
            <div className="permit_list_item_image_wrapper">
                <img src={props.user.profileImageUrl} alt="user icon" />
            </div>
            <div className="hr_page_item_data">
                <h3>{`${props.user.lastName} ${props.user.firstName} ${props.user.middleName}`}</h3>
                <p>@{props.user.username}</p>
                <p>Date of Birth: {props.user.dateOfBirth}</p>
            </div>
        </div>
    )
}