import "./OrderCreate.css"
import { Link } from 'react-router-dom';
import { useState, useEffect } from "react"
import React, { useRef } from 'react';
import Loader from "../../components/UI/Loader"
import axios from "../../plugins/axios";
import { useTranslation } from "react-i18next";


export default function OrderCreate(){
    const { t } = useTranslation();
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const [loading, setLoading] = useState(false);
    
    const ordererRef = useRef(null);
    const quantityRef = useRef(null);
    const remarksRef = useRef(null);
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [products, setProducts] = useState([]);

    console.log(products)
    useEffect(()=>{
        const fetchData = async () =>{
            try{
                setLoading(true);
                const response = await axios.get('/product/all') 
                setProducts(response.data)
            }
            catch(err){
                console.log(err);
                alert(err)
            }finally{
                setLoading(false)
            }   
        }
        fetchData()
    }, [])

    const handleFormSubmit = async(e) =>{
        e.preventDefault();
        const formData = {
            orderer: ordererRef.current.value, 
            authorId: currentUser._id, 
            productId: selectedProduct._id, 
            quantity:quantityRef.current.value, 
            remarks: ''
        }
        if(quantityRef.current.value > selectedProduct.quantity){
            alert(`Only ${quantityRef.current.value} is available.`)
            quantityRef.current.value = null
            return false
        }
        try {
            const response = await axios.post('/order/create', formData);
            alert('Data submitted successfully:', response.data);
            window.location.href = '/order';
        } catch (error) {
            console.error('Error:', error);
        }

        console.log('submit')
    }

    // const selectProduct = (productId) => {
    //     console.log(productId)
    //     // setProduct(null)
    //     // const product = products.find(product => product.id == productId);
    //     // if (product) {
    //     //     setProduct(product);
    //     // } else {
    //     //     alert(`Product with ID ${productId} not found`);
    //     // }
    // };
    const handleProductChange = (event) => {
        const productId = event.target.value;
        const product = products.find(p => p._id === productId);
        setSelectedProduct(product);
    };

    if(!currentUser){
        return <NotAuthenticated />
    }
    if(loading){
        return <Loader />
    }
    if(products){
        return(
            <div className="orderPage">
                <div className="container">
                <Link to="#" onClick={() => window.history.back()}>Back</Link>
                    <form className="create_order_card">
                        <h1 className="create_order_title">{t("create_order_page_title", "Create New Order")}</h1>
                        <p className="create_order_description">{t("create_order_subtitle", "Lorem ipsum dolor sit amet consectetur, adipisicing elit.")}</p>
    
                        <div className="create_order_input_item">
                            <label className="create_order_label" htmlFor="title">{t("create_order_title", "Orderer")}:</label><br/>
                            <input
                                className="create_order_input"
                                type="text"
                                id="orderer"
                                ref={ordererRef}
                            />
                        </div>
    
                        <div className="create_order_input_item">
                            <label className="create_order_label" htmlFor="type">{t("create_order_type", "Type")}:</label><br/>
                            <select
                                className="create_order_input"
                                id="type"
                                onChange={handleProductChange} 
                                value={selectedProduct ? selectedProduct._id : ''}
                            >
                                <option className="create_order_option" onClick={()=>setProduct(null)}>Select Product</option>
                                {products?.map((product) => (
                                    <option key={product._id} value={product._id} className="create_order_option"> 
                                        {product.title}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {selectedProduct && <p>Available: <strong>{selectedProduct.quantity}</strong>{` ${selectedProduct.measurementUnit}`}</p>}
                        <div className="create_order_input_item">
                            <label className="create_order_label" htmlFor="description">{t("create_order_quantity", "Quantity")}:</label><br/>
                            <input
                                className="create_order_input"
                                id="quantity"
                                type="number"
                                ref={quantityRef}
                            />
                        </div>
    
                        <div className="create_order_input_item">
                            <label className="create_order_label" htmlFor="remarks">{t("create_order_remarks", "Remarks")}:</label><br/>
                            <textarea
                                className="create_order_input"
                                type="text"
                                id="remarks"
                                ref={remarksRef}
                            />
                        </div>
                        <div className="create_order_controls">
                            <button onClick={handleFormSubmit} className="create_order_submit">{t("create_order_submit", "Submit")}</button>
                            <a href="/order" className="create_order_close">{t("create_order_close", "Close")}</a>
                        </div>            
                    </form>
                </div>
            </div>
        )
    }
    
}