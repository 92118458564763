import "./HomeItem.css"
import { useTranslation } from "react-i18next";


export default function HomeItem(props){
    const { t } = useTranslation();

    return(
        <div className="home_item_wrapper">
            <img src={props.icon} alt="icon" className="home_item_icon"/>
            <div className="home_item_info">
                <h2>{props.title}</h2>
            </div>
            <a href={props.link} className="home_item_link">{t("home_link_open", "Open")}</a>
        </div>
    )
}