import "./OrderPage.css"
import {Link } from "react-router-dom"
import { useState, useEffect } from "react"
import axios from "../../plugins/axios";
import Loader from "../../components/UI/Loader"
import NotAuthenticated from "src/components/layout/NotAuthenticated";
import OrderPageItem from "./OrderPageItem"

export default function OrderPage(){
    const [selectedState, setSelectedState] = useState('Pending')
    const [orders, setOrders] = useState(null)
    const [loading, setLoading] = useState(false)
    const [pendingItems, setPendingItems] = useState([]);
    const [preparingItems, setPreparingItems] = useState([]);
    const [preparedItems, setPreparedItems] = useState([]);
    const [sentItems, setSentItems] = useState([]);
    const [deliveredItems, setDeliveredItems] = useState([]);
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    


    useEffect(()=>{
        const fetchData = async () =>{
            try{
                setLoading(true);
                const response = await axios.get('/order/all') 
                setOrders(response.data)
                setPendingItems(response.data.filter(item => item.state === 'Pending'))
                setPreparingItems(response.data.filter(item=>item.state === "Preparing"))
                setPreparedItems(response.data.filter(item=>item.state === "Prepared"))
                setSentItems(response.data.filter(item=>item.state === "Sent"))
                setDeliveredItems(response.data.filter(item=>item.state === "Delivered"))
            }
            catch(err){
                console.log(err);
                alert(err)
            }finally{
                setLoading(false)
            }   
        }
        fetchData()
    }, [])

    if(!currentUser){
        return <NotAuthenticated />
    }
    if(loading){
        return <Loader />
    }
    return(
        <div className="permitPage">
                <div className="container">
                    <div className="permit-header">
                        <h1>Orders Management</h1>
                        <Link to="create" className="permit_page_create">Create</Link>
                    </div>
                    <div className="permit_navigation">
                        <ul className="permit_state_list">
                            {selectedState==='Pending' ? (<li className="permit_page_state_selected">Pending</li>) : (<li className="permit_page_state" onClick={()=>{setSelectedState('Pending')}}>Pending</li>) }
                            {selectedState==='Preparing' ? (<li className="permit_page_state_selected">Preparing</li>) : (<li className="permit_page_state" onClick={()=>{setSelectedState('Preparing')}}>Preparing</li>) }
                            {selectedState==='Prepared' ? (<li className="permit_page_state_selected">Prepared</li>) : (<li className="permit_page_state" onClick={()=>{setSelectedState('Prepared')}}>Prepared</li>) }
                            {selectedState==='Sent' ? (<li className="permit_page_state_selected">Sent</li>) : (<li className="permit_page_state" onClick={()=>{setSelectedState('Sent')}}>Sent</li>) }                         
                            {selectedState==='Delivered' ? (<li className="permit_page_state_selected">Delivered</li>) : (<li className="permit_page_state" onClick={()=>{setSelectedState('Delivered')}}>Delivered</li>) }                         
                        </ul>
                    </div>
                    <div className="permit_page_search_wrapper">
                        <div>
                            <div className="permit_page_search_title">
                                <a href="/order/search" className="permit_search_link">Search</a>
                            </div>
                        </div>
                    </div>
                    {selectedState === "Pending" && !loading ? 
                    pendingItems.map((order=>{
                        return (
                            <OrderPageItem order={order} key={order._id} />
                        )
                        })) 
                        : null}
                    {selectedState === "Pending" && !loading && pendingItems.length === 0 ? (
                        <h2 data-aos="fade-up">No Orders</h2>
                    ) : null}

                    {selectedState === "Preparing" && !loading ? 
                    preparingItems.map((order=>{
                        return (
                            <OrderPageItem order={order} key={order._id} />
                        )
                        })) 
                        : null}
                    {selectedState === "Preparing" && !loading && preparingItems.length === 0 ? (
                        <h2 data-aos="fade-up">No orders</h2>
                    ) : null}

                    {selectedState === "Prepared" && !loading ? 
                    preparedItems.map((order=>{
                        return (
                            <OrderPageItem order={order} key={order._id} />
                        )
                        })) 
                        : null}
                    {selectedState === "Prepared" && !loading && preparedItems.length === 0 ? (
                        <h2 data-aos="fade-up">No permits</h2>
                    ) : null}
                    
                    {selectedState === "Sent" && !loading ? 
                    sentItems.map((order=>{
                        return (
                            <OrderPageItem order={order} key={order._id} />
                        )
                        })) 
                        : null}
                    {selectedState === "Sent" && !loading && sentItems.length === 0 ? (
                        <h2 data-aos="fade-up">No Orders</h2>
                    ) : null}


                    {selectedState === "Delivered" && !loading ? 
                    deliveredItems.map((order=>{
                        return (
                            <OrderPageItem order={order} key={order._id} />
                        )
                        })) 
                        : null}
                    {selectedState === "Delivered" && !loading && deliveredItems.length === 0 ? (
                        <h2 data-aos="fade-up">No permits</h2>
                    ) : null}
                </div>
        </div>
    )
}