import { useState } from "react"
import {Link} from "react-router-dom"
import axios from "../../plugins/axios";
import { useTranslation } from "react-i18next";
import "./LoginPage.css"

export default function LoginPage(){
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        username: '',
        password: ''
    })
    const [usernameIsValid, setUsernameIsValid] = useState(true);
    const [passwordIsValid, setPasswordIsValid] = useState(true);
    const [loading, setLoading] = useState(false);

    function isUsernameValid(username) {
        // const regex = /^[a-z]{4,}$/;
        // return regex.test(username);
        return true
    }

    function isPasswordValid(password) {
      `// const regex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]{8,}$/;`
        // return regex.test(password);
        return true
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUsernameIsValid(isUsernameValid(formData.username));
        setPasswordIsValid(isPasswordValid(formData.password));
        setFormData({ ...formData, [name]: value });
      };

    const handleFormSubmit = async(e) => {
        e.preventDefault();
        if(usernameIsValid && passwordIsValid){
            try {
                setLoading(true);
                console.log(formData)
                const res = await axios.post("auth/login", formData)
                console.log("res data "+ JSON.stringify(res.data))
                localStorage.setItem("currentUser", JSON.stringify(res.data));
                setLoading(false)
                window.location.href = '/';
              } catch (err) {
                console.log(err);
                alert(t("login_error", "Check your credentials and try again."));
                formData.username = "";
                formData.password = "";
                return false;
              }
        }
    }
    return(
      <div className="login_page">
          <div className="login_card"> 
          <h1 className="signup_title">{t("login_title") || "Log In"}</h1>
          {loading ? <h2>{t("login_loading", "Loading")}</h2> : null}
          <form onSubmit={handleFormSubmit}>
            <div className="login_input_item">
              <label className="login_label" htmlFor="username">{t("login_username", "Username")}:</label>
              <input className="login_input"
                type="text"
                id="username"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
              />
              {usernameIsValid || formData.username === '' ? null : <p className="login_warning">{t("login_username_validation", "Username should contain letters a-z, numbers 0-9 with no spaces")}</p>}            
            </div>
            <div className="login_input_item">
              <label className="login_label" htmlFor="password">{t("login_password","Password")}:</label>
              <input className="login_input"
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
              />
              {passwordIsValid || formData.password === '' ? null : <p className="login_warning">{t("login_password_validation", "Password should contain letters a-z, numbers 0-9, symbols '@,!,#,$,%' with no spaces")}</p>}
            </div>
            <button className='login_submit' type="submit">{t("login_submit") || "Submit"}</button>
            <p className="login_redirect">{t("login_redirect_text", "Don't have account?")} <Link to='/signup'>{t("signup_redirect_text", "Sign Up")}</Link></p>
          </form>
        </div>
      </div>
    )
}
