import "./OrderPageItem.css"
import OrderIcon from "../../assets/orderIcon.svg"


export default function OrderPageItem(props){
    const getDate = (date) =>{
        const newDate = new Date(date);
        let minutes = String(newDate.getMinutes()).padStart(2, '0');
        const dateString = `${newDate.getHours()}:${minutes}, ${newDate.getDate()}/${newDate.getMonth()}/${newDate.getFullYear()}`
        return dateString
    }
    return (
        <div className="permit_list_item">
            <div className="permit_list_item_image_wrapper">
                <img src={OrderIcon} alt="permit icon" />
            </div>
            <div>
                <h3>{`${props.order.productTitle}:  ${props.order.orderTitle}`}</h3>
                <p>Orderer: {props.order.orderer}</p>
                <p>State: {props.order.state}</p>
                <p>Created: {getDate(props.order.date)}</p>
                <button className="order_item_button" onClick={()=>{window.location.href=`/order/${props.order._id}`}}>See Details</button>
            </div>
        </div>
    )
}