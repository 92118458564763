import { initializeApp } from "firebase/app";
import {getStorage} from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyAYzwJN2Ep_WJM39zoa8sin3NE9jOFABIY",
  authDomain: "factoryp-ba301.firebaseapp.com",
  projectId: "factoryp-ba301",
  storageBucket: "factoryp-ba301.appspot.com",
  messagingSenderId: "170207841997",
  appId: "1:170207841997:web:f893fcc257304ef3452384",
  measurementId: "G-YTLGBDGDEY"
};

const app = initializeApp(firebaseConfig);
export const imageDb = getStorage(app);