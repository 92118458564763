import { Link } from 'react-router-dom';
import { useState, useEffect } from "react"
import React, { useRef } from 'react';
import Loader from "../../components/UI/Loader"
import axios from "../../plugins/axios";
import { useTranslation } from "react-i18next";
import "./PermitCreate.css"


export default function PermitCreate(){
    const { t } = useTranslation();
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const [loading, setLoading] = useState(false);
    
    const titleRef = useRef(null);
    const typeRef = useRef(null);
    const descriptionRef = useRef(null);
    const locationRef = useRef(null);
    const startDateRef = useRef(null);
    const endDateRef = useRef(null);
    const numberOfPersonsRef = useRef(null);
    const [equipments, setEquipments] = useState([])
    const [selectedEquipments, setSelectedEquipments] = useState([]);

    const handleEquipmentToggle = (equipment) => {
        if (selectedEquipments.includes(equipment)) {
            setSelectedEquipments(selectedEquipments.filter(item => item !== equipment));
        } else {
            setSelectedEquipments([...selectedEquipments, equipment]);
        }
    };
    
    

    useEffect(()=>{
        const fetchData = async() =>{
            setLoading(true)
            const response = await axios.get("/equipment")
            setEquipments(response.data)
            setLoading(false)
        }
        fetchData()
    }, [])
    

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const formData = {
            title: titleRef.current.value,
            type: typeRef.current.value,
            description: descriptionRef.current.value,
            location: locationRef.current.value,
            startDate: startDateRef.current.value,
            endDate: endDateRef.current.value,
            numberOfPersons: numberOfPersonsRef.current.value,
            equipments: selectedEquipments,
            state: "Draft",
            userId: currentUser._id
        };

        try {
            const response = await axios.post('/permit/create', formData);
            alert('Data submitted successfully:', response.data);
            window.location.href = '/permit';
        } catch (error) {
            console.error('Error:', error);
        }
    };

    if(!currentUser){
        return <NotAuthenticated />
    }
    if(loading){
        return <Loader />
    }
    return(
        <div className="permitPage">
            <div className="container">
            <Link to="#" onClick={() => window.history.back()}>{t("permit_search_goback", "Go Back")}</Link>
                <form className="create_permit_card">
                    <h1 className="create_permit_title">{t("create_permit_page_title", "Draft New Permit")}</h1>
                    <p className="create_permit_description">{t("create_permit_subtitle", "Fill in the form below to issue a new permit")}</p>

                    <div className="create_permit_input_item">
                        <label className="create_permit_label" htmlFor="title">{t("create_permit_title", "Title")}:</label><br/>
                        <input
                            className="create_permit_input"
                            type="text"
                            id="title"
                            ref={titleRef}
                        />
                    </div>

                    <div className="create_permit_input_item">
                        <label className="create_permit_label" htmlFor="type">{t("create_permit_type", "Type")}:</label><br/>
                        <select
                            className="create_permit_input"
                            id="type"
                            ref={typeRef}
                        >
                            <option className="create_permit_option" value="Electrical">{t("create_permit_type_electrical", "Electrical")}Electrical</option>
                            <option className="create_permit_option" value="Mechanical">{t("create_permit_type_mechanical", "Mechanical")}</option>
                            <option className="create_permit_option" value="Hot Work">{t("create_permit_hotwork", "Hot Work")}</option>
                            <option className="create_permit_option" value="Weigh Lifting">{t("create_permit_type_weighlifting", "Weigh Lifting")}</option>
                            <option className="create_permit_option" value="Cleaning">{t("create_permit_type_cleaning", "Cleaning")}</option>
                        </select>
                    </div>

                    <div className="create_permit_input_item">
                        <label className="create_permit_label" htmlFor="description">{t("create_permit_description", "Description")}:</label><br/>
                        <textarea
                            className="create_permit_input"
                            id="description"
                            ref={descriptionRef}
                        />
                    </div>

                    <div className="create_permit_input_item">
                        <label className="create_permit_label" htmlFor="location">{t("create_permit_location", "Location")}:</label><br/>
                        <input
                            className="create_permit_input"
                            type="text"
                            id="location"
                            ref={locationRef}
                        />
                    </div>

                    <div className="create_permit_input_item">
                        <label className="create_permit_label" htmlFor="startDate">{t("create_permit_startDate", "Planned Start Date")}:</label><br/>
                        <input
                            className="create_permit_input"
                            type="date"
                            id="startDate"
                            ref={startDateRef}
                        />
                    </div>

                    <div className="create_permit_input_item">
                        <label className="create_permit_label" htmlFor="endDate">{t("create_permit_endDate", "Planned End Date")}:</label><br/>
                        <input
                            className="create_permit_input"
                            type="date"
                            id="endDate"
                            ref={endDateRef}
                        />
                    </div>

                    <div className="create_permit_input_item">
                        <label className="create_permit_label" htmlFor="numberOfPersons">{t("create_permit_numberOfPersons", "Number of Workers")}:</label><br/>
                        <input
                            className="create_permit_input"
                            type="number"
                            id="numberOfPersons"
                            ref={numberOfPersonsRef}
                            min={0}
                        />
                    </div> 

                    <div className="create_permit_input_item">
                        <label className="create_permit_label" htmlFor="equipment">{t("create_permit_equipments", "Equipments")}:</label><br/>
                        <ul className='create_permit_equipment_list'>
                            {equipments.map((equipment) => (
                                <li key={equipment._id}>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={selectedEquipments.includes(equipment)}
                                            onChange={() => handleEquipmentToggle(equipment)}
                                        />
                                        {equipment.title}
                                    </label>
                                </li>
                            ))}
                        </ul>
                        <div>
                        {selectedEquipments.length !== 0 ? <label className="create_permit_label">{t("create_permit_selected_equipments", "Selected Equipments")}:</label> : null}
                        
                            <ul className='create_permit_equipment_list'>
                                {selectedEquipments.map((equipment) => (
                                    <li key={equipment._id}>{equipment.title}</li>
                                ))}
                            </ul>
                        </div>
                    </div>   
                    <div className="create_permit_controls">
                        <button onClick={handleFormSubmit} className="create_permit_submit">{t("create_permit_submit", "Submit")}</button>
                        <a href="/permit" className="create_permit_close">{t("create_permit_close", "Close")}</a>
                    </div>            
                </form>
            </div>
        </div>
    )
}