import { Routes, Route } from "react-router-dom"

import Navigation from "./components/layout/Navigation"
import LoginPage from "./pages/Login/LoginPage"
import SignupPage from "./pages/Signup/SignupPage"
import HomePage from "./pages/Home/HomePage"
import StartPage from "./pages/Start/StartPage"
import Account from "./pages/Account/Account"
import HrPage from "./pages/HR/HrPage"
import CreateUserPage from "./pages/HR/CreateUserPage"
import PermitPage from "./pages/Permit/PermitPage"
import PermitCreate from "./pages/Permit/PermitCreate"
import Permit from "./pages/Permit/Permit"
import PermitSearch from "./pages/Permit/PermitSearch"
import OrderPage from "./pages/Order/OrderPage"
import OrderCreate from "./pages/Order/OrderCreate"
import Order from "./pages/Order/Order"
import WarehousePage from "./pages/Warehouse/WarehousePage"
import WarehouseCreate from "./pages/Warehouse/WarehouseCreate"
import WarehouseRecords from "./pages/Warehouse/WarehouseRecords"

import User from "./pages/HR/User"
import EditUserPage from "./pages/HR/EditUserPage"


import NotFoundPage from "./pages/NotFound/NotFoundPage"
import TestingPage from "./pages/Testing/Testing"



localStorage.setItem('lng', 'en')
localStorage.setItem('currentPage', 'Home');

function App() {
    return(
        <>
        <Navigation />
        <Routes>
            <Route path="/">
                <Route index element={<HomePage />} />
                <Route path="start" element={<StartPage />} />
                <Route path="login" element={<LoginPage />} />
                <Route path="signup" element={<SignupPage />} />
                <Route path="account" element={<Account />} />
                <Route path="test" element={<TestingPage />} />
                <Route path="hr">
                    <Route index element={<HrPage/>}/>
                    <Route path="create" element={<CreateUserPage/>} />
                    <Route path="user">
                        <Route path=":username" element={<User />} />
                    </Route>
                    <Route path="edit-user">
                        <Route path=":id" element={<EditUserPage />} />
                    </Route>
                </Route>
                <Route path="permit">
                    <Route index element={<PermitPage />} />
                    <Route path="create" element={<PermitCreate />} />
                    <Route path=":id" element={<Permit />} />
                    <Route path="search" element={<PermitSearch />} />
                </Route>
                <Route path="order">
                    <Route index element={<OrderPage />} />
                    <Route path="create" element={<OrderCreate />} />
                    <Route path=":id" element={<Order />} />
                </Route>
                <Route path="warehouse">
                    <Route index element={<WarehousePage />} />
                    <Route path="create" element={<WarehouseCreate />} />
                    <Route path=":id" element={<WarehouseRecords />} />
                </Route>
            </Route>
            <Route path='*' element={<NotFoundPage />}/> 
        </Routes>
        </>
    )
}

export default App;
