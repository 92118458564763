import { useState, useRef, useEffect } from "react"
import { useParams } from 'react-router-dom';
import axios from "../../plugins/axios";
import { useTranslation } from "react-i18next";
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { mkConfig, generateCsv, download } from "export-to-csv";
import "./EditUserPage.css"



export default function EditUserPage(){
    const { t } = useTranslation();
    const {id} = useParams()
    const [loading, setLoading] = useState(false) 

    const usernameRef = useRef(null);
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const middleNameRef = useRef(null);
    const dateOfBirthRef = useRef(null);
    const passwordRef = useRef(null);
    const userEmail = useRef(null);

    
    const [rolesList, setRolesList] = useState(['User', "System Administrator", 'PermitRequestor', 'PermitVerifier', 'PermitActivator']);
    const [rolesSelected, setRolesSelected] = useState([]);


   

    const handleSubmit = async (event) => {
        event.preventDefault();


        const userData = {
            username: usernameRef.current.value,
            firstName: firstNameRef.current.value,
            lastName: lastNameRef.current.value,
            middleName: middleNameRef.current.value,
            dateOfBirth: dateOfBirthRef.current.value,
            roles: rolesSelected
        };

        try {
            console.log("start update")
        const response = await axios.put(`/auth/edit/${id}`, userData);
        console.log("finish update")

        alert('User updated successfully:', response.data);      
        window.location.href = '/hr';      
        } catch (error) {
        console.error('Error updating user:', error);
        }
    };

    const addRole = (role) => {
        // Check if the role is already selected
        if (!rolesSelected.includes(role)) {
          // If the role is not already selected, add it to the rolesSelected array
          setRolesSelected(prevRoles => [...prevRoles, role]);
        }
      };
    
    const removeRole = (role) => {
        setRolesSelected(prevRoles => prevRoles.filter(prevRole => prevRole !== role));
    };

    useEffect(() => {
        const fetchData = async () =>{
            const response = await axios.get(`/auth/${id}`)
            usernameRef.current.value = response.data.username
            firstNameRef.current.value = response.data.firstName
            lastNameRef.current.value = response.data.lastName
            middleNameRef.current.value = response.data.middleName
            dateOfBirthRef.current.value = response.data.dateOfBirth
            setRolesSelected(...rolesSelected, response.data.roles)
        }
        fetchData()
      }, []);

    return(
        <div className="createuser_page">
                <div className="createuser_card">
                <h1 className="createuser_title">Edit User</h1>
                {loading ? <h2>{t("createuser_loading", "Loading")}</h2> : null}
                <form onSubmit={handleSubmit}>
                <div className="createuser_input_item">
                    <label className="createuser_label" htmlFor="firstName">{t("createuser_firstName", "First Name")}:</label><br/>
                    <input type="text" className="createuser_input" ref={firstNameRef} required />
                </div>
                <div className="createuser_input_item">
                    <label className="createuser_label" htmlFor="lastName">{t("createuser_lastName", "Last Name")}:</label><br/>
                    <input type="text" className="createuser_input" ref={lastNameRef} required />
                </div>
                <div className="createuser_input_item">
                    <label className="createuser_label" htmlFor="middleName">{t("createuser_middleName", "Middle Name")}:</label><br/>
                    <input type="text" ref={middleNameRef} className="createuser_input" required />
                </div>
                <div className="createuser_input_item">
                    <label className="createuser_label" htmlFor="dateOfBirth" >{t("createuser_dateOfBirth", "Date Of Birth")}:</label><br/>
                    <input type="date" ref={dateOfBirthRef} className="createuser_input" required />
                </div>
                <div className="createuser_input_item">
                    <label className="createuser_label" htmlFor="username">{t("createuser_username", "Username")}:</label><br/>
                    <input type="text" ref={usernameRef} className="createuser_input" required />
                </div>
                <div className="createuser_input_item">
                    {rolesList?.length !== rolesSelected?.length && (
                    <div>
                        <h3 className="create_user_roles_title">Roles</h3>
                        <span className="create_user_roles_list_instruction">Click to add role to user.</span>
                    </div>
                    )}
                    <ul className="create_user_roles_list">
                        {rolesList.map(role=>{
                            if(!rolesSelected.includes(role)){
                                 return (
                                        <li className="create_user_roles_list_item" key={role} onClick={()=>addRole(role)}>{role}</li>
                                    )
                                }
                        })}
                    </ul>
                    {rolesSelected?.length > 0 && (<div>
                        <h3 className="create_user_roles_title">Selected Roles</h3>
                        <span className="create_user_roles_list_instruction">Click to delete role from user.</span>
                    </div>)}
                    <ul className="create_user_roles_list">
                        {rolesSelected.map(role=>{
                            if(role == "User"){
                                return(
                                    <li className="create_user_roles_list_item_nonselectable" key={role}>{role}</li>
                                )
                            }else{
                                return (
                                    <li className="create_user_roles_list_item" key={role} onClick={()=>removeRole(role)}>{role}</li>
                                )
                            }
                        })}
                    </ul>
                </div>    
                <div className="create_user_controls">
                    <button className='create_user_submit' type="submit">{t("createuser_submit") || "Submit"}</button>
                    <a href="/hr" className="create_user_close">{t("create_permit_close", "Close")}</a>
                </div>
                </form>
            </div>
        </div>
    )
}