import {Link } from "react-router-dom"
import { useState, useEffect } from "react"
import axios from "../../plugins/axios";
import Loader from "../../components/UI/Loader"
import HrPageItem from "./HrPageItem"
import NoAccess from "../../components/layout/NoAccess"
import "./HrPage.css"




export default function HrPage(){
    const [users, setUsers] = useState([])
    const [filteredUsers, setFilteredUsers] = useState([])
    const [searchQuery, setSearchQuery] = useState('')
    const [loading, setLoading] = useState(false)

    const currentUser = JSON.parse(localStorage.getItem("currentUser"));



    useEffect(()=>{
        const fetchData = async () =>{
            try{
                setLoading(true);
                const response = await axios.get('/auth') 
                setUsers(response.data)
            }
            catch(err){
                console.log(err);
                alert(err)
            }finally{
                setLoading(false)
            }   
        }
        fetchData()
    }, [])

    useEffect(() => {
        // Filter users based on searchQuery
        const filtered = users.filter(user =>
            user.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.lastName.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredUsers(filtered);
    }, [searchQuery, users]);
    
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };
    console.log(currentUser)
    if(currentUser.roles.includes('System Administrator')){
        console.log(true)
    }

if (currentUser.roles.includes('System Administrator') || currentUser.roles.includes('HR')){
    return(
        <div className="usersPage">
                <div className="container">
                    <div className="users-header">
                        <h1>Human Resource Management</h1>
                    </div>
                    <div className="users_page_search_wrapper">
                        {currentUser.roles.includes('System Administrator') && <Link to="create" className="users_page_create">Add User</Link>}                       
                        <input type="text" placeholder="Search" className="users_page_search" value={searchQuery} onChange={handleSearchChange} />
                    </div>
                    {!users || loading ? (
                        <div className="loader_wrapper" data-aos="fade-up">
                            <Loader />
                        </div>
                    ) : null}
                    <div className="users_page_list_wrapper" data-aos="fade-up">
                        {users?.length > 0 && !loading && searchQuery == '' && users.map((user=>{
                            return (
                                <HrPageItem user={user} key={user._id} />
                            )   
                            }))}
                        {users?.length > 0 && !loading && searchQuery !== '' && filteredUsers.map((user=>{
                            return (
                                <HrPageItem user={user} key={user._id} />
                            )   
                            }))}
                    </div>
                    
                    {users?.length === 0 && (
                        <h2 data-aos="fade-up">No users</h2>
                    )}
                </div>
        </div>
    )
}else{
    return <NoAccess />
}
}