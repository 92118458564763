import NotAuthenticated from "src/components/layout/NotAuthenticated"; 
import HomeItem from "./HomeItem";
import { useTranslation } from "react-i18next";
import "./HomePage.css"


import EquipmentIcon from '../../assets/equipment_icon.svg'
import HrIcon from '../../assets/hr_icon.svg'
import OrdersIcon from '../../assets/orders_icon.svg'
import PermitIcon from '../../assets/ptw_icon.svg'
import WarehouseIcon from '../../assets/warehouse_icon.svg'




export default function HomePage(){
    const { t } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem("language", lng);
      };
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if(!currentUser){
        return(
        <div className="container" data-aos="fade-up">
             <NotAuthenticated />
        </div>
        )
    } 

    return(
        <div className="container" data-aos="fade-up">
            {currentUser ? (<h1 className="home_title_h1" >{t("home_welcome", "Welcome back")}, {currentUser.firstName}!</h1>) : <h1 className="home_title_h1" >Welcome, User!</h1>}
            <div className="home_navigation">
                <HomeItem icon={PermitIcon} title={t("home_permit_to_work", "Permit to Work")} link="/permit"/>
                <HomeItem icon={OrdersIcon} title={t("home_orders", "Orders")} link="/order" />
                <HomeItem icon={HrIcon} title={t("home_hr", "Human Resource")} link="/hr" />
                <HomeItem icon={WarehouseIcon} title={t("home_warehouse", "Warehouse")} link="/warehouse"/>
                <HomeItem icon={EquipmentIcon} title={t("home_equipments", "Equipments")} />
            </div>
        </div>
    )
}