import React, { useEffect, useState, useRef } from "react"
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import "./WarehouseRecords.css"
import Loader from "../../components/UI/Loader"
import NotAuthenticated from "src/components/layout/NotAuthenticated";
import axios from "../../plugins/axios";
import {v4} from "uuid"

export default function WarehouseRecords(){
    const {id} = useParams()
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const [product, setProduct] = useState([])
    const [records, setRecords] = useState([])
    const [type, setType] = useState('')
    const [showUpdateQuantity, setShowUpdateQuantity] = useState(false)
    const [loading, setLoading] = useState(false) 

    const quantityRef = useRef(0)

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const response = await axios.get(`/product/${id}`);
                setProduct(response.data)
                setRecords(response.data.records)
            } catch (error) {
                console.error('Error fetching permit:', error);
            }finally{
                setLoading(false)
            } 
        };
        fetchData();
    }, []);

    const handleSubmit = async () =>{
        try {
            setLoading(true)
            if(type !== "add" && type !== "sub"){
                alert("Please, choose Add or Subtract option")
                return false;
            }
            if(quantityRef.current.value == 0){
                alert("Please, enter the quantity")
                return false;
            }
            const formData = {
                productId: product._id,
                type: type,
                quantity: parseFloat(quantityRef.current.value),
                authorId: currentUser._id
            }
            await axios.post(`/record/create`, formData);
            window.location.reload();
        } catch (error) {
            console.error('Error fetching permit:', error);
        }finally{
            setLoading(false)
        } 
    }
    const deleteProduct = async () => {
        console.log('delete prod')
    }

    const updateQuantity = async () =>{
        console.log('update quantity')
    }
    const toggleUpdateQuantity = () => {
        setShowUpdateQuantity(!showUpdateQuantity)
    }

    const getDate = (date) =>{
        const newDate = new Date(date);
        let minutes = String(newDate.getMinutes()).padStart(2, '0');
        const dateString = `${newDate.getHours()}:${minutes}, ${newDate.getDate()}/${newDate.getMonth()}/${newDate.getFullYear()}`
        return dateString
    }

    console.log(records)
    if(!currentUser){
        return <NotAuthenticated />
    }
    if(loading){
        return <Loader />
    }

        return(
            <div className="permit">
                <div className="container">
                    <div className="permit_card">
                        <h1>Product Details</h1>
                        <h3>Product Title:<strong>{product.title}</strong></h3>
                        <p>Quantity: <strong>{`${product.quantity} ${product.measurementUnit}`}</strong></p>
                        <h3>Actions</h3>
                        <div className="permit_actions">
                            <button onClick={toggleUpdateQuantity} className="permit_action_item_submit">Add New Record</button>
                            <button onClick={deleteProduct} className="permit_action_item_delete">Delete</button>
                        </div>
                    </div>
                    {showUpdateQuantity && (
                        <div className="permit_card">
                        <h1>Add New Record</h1>
                            <div className="warehouse_create_input_item"> 
                                <label className="warehouse_create_label" htmlFor="quantity">Quantity:</label><br/>
                                <input
                                    type="number"
                                    className="warehouse_create_input"
                                    id="quantity"
                                    ref={quantityRef}
                                />
                            </div>
                            <div>
                                {type=="add" ? <button className="product_record_type_selected" onClick={()=>{setType('add')}}>Add +</button> : <button className="product_record_type" onClick={()=>{setType('add')}}>Add +</button>}
                                {type=="sub" ? <button className="product_record_type_selected" onClick={()=>{setType('sub')}}>Subtract -</button> : <button className="product_record_type" onClick={()=>{setType('sub')}}>Subtract -</button>}
                                
                                
                            </div>
                            <div className="product_record_buttons">
                                <button className="product_record_button_add" onClick={handleSubmit}>Add</button>
                                <button className="product_record_button_hide" onClick={toggleUpdateQuantity}>Hide</button>
                            </div>
                        </div>
                    )}
                    
                    
                    <div className="permit_card">
                    <h1>Records</h1>
                        {records.map((record) => (
                            <div key={record._id} className="permit_list_items">
                                {record.type == "add" && <h3 className="product_record_heading"><span className="product_record_type_add">Add ( + ) </span> {`${record.quantity} ${product.measurementUnit}`}</h3>}
                                {record.type == "sub" && <h3 className="product_record_heading"><span className="product_record_type_sub">Subtract ( - ) </span> {`${record.quantity} ${product.measurementUnit}`}</h3>}
                                <p>Updated by: <Link to={`/hr/${record.author}`}>@{record.author}</Link></p>
                                <p>Changed from <strong>{`${record.previousValue} ${product.measurementUnit}`}</strong> to <strong>{`${record.newValue} ${product.measurementUnit}`}</strong></p>
                                <p>Changed at: {getDate(record.date)}</p>
                            </div>
                        ))}
                    </div>
                </div>
                       
        </div>                      
        ) 
}









