import StopSign from '../../assets/stop_sign.svg'
import { useTranslation } from "react-i18next";


export default function NoAccess(){
    const { t } = useTranslation();

    return (
        <div className='notauth_wrapper'>
            <img className='notauth_image' src={StopSign} alt="icon" />
            <h2>{t("auth_notauth", "No Access")}.<br/> {t("no_ccess", "User has no access to this page")}</h2>
        </div>
    )
}

