import React, { useEffect, useState, useRef } from "react"
import { useParams } from 'react-router-dom';
import "./User.css"
import Loader from "../../components/UI/Loader"
import axios from "../../plugins/axios";
import {v4} from "uuid"
import NotAuthenticated from "src/components/layout/NotAuthenticated";


export default function User(){
    const {username} = useParams()
    const [userData, setUserData] = useState([])
    const [roles, setRoles] = useState([])
    const [loading, setLoading] = useState(false) 
    const [showPasswordInput, setShowPasswordInput] = useState(false)
    const [invalidPassword, setInvalidPassword] = useState(false)
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));


    const oldPasswordRef = useRef(null);
    const newPasswordRef = useRef(null);


    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const response = await axios.get(`/auth/username/${username}`);
                setUserData(...userData, response.data)
                setRoles(...roles, response.data.roles)
            } catch (error) {
                console.error('Error fetching permit:', error);
            }finally{
                setLoading(false)
            }
        };
        fetchData();
    }, []);
    

    const toggleShowPassword = () =>{
        setShowPasswordInput(!showPasswordInput)
    }

    const handlePasswordChange = async () =>{
        setLoading(true)
        const formData = {
            userId: userData._id,
            password: oldPasswordRef.current.value,
            newPassword: newPasswordRef.current.value
        }
        setInvalidPassword(false)

        try{
            await axios.put('/auth/changePassword', formData);
            alert('Password updated successfully');
            oldPasswordRef.current.value = '';
            newPasswordRef.current.value = '';
            setShowPasswordInput(false)
            
        }catch(err){
            setInvalidPassword(true)
        }
        finally{
            setLoading(false)
        }
    }
    const deleteUser = async() => {
        try{
            setLoading(true)
            await axios.delete(`/auth/delete/${userData._id}`)
            .then( () => {
                alert('User deleted successfully');
                window.location.href = "/hr"
                })
        }catch(err){
            console.error('Error deleting user:', err);
        }
        finally{
            setLoading(false)
        }
    }
    const logOut = () =>{
        localStorage.setItem("currentUser", null)
        window.location.href = "/"
    }

    if(!currentUser){
        return <NotAuthenticated />
    }
    if(loading){
        return <Loader />
    }
    if (userData){
        return(
            <div className="permit">
                <div className="container">
                    <div className="user_card">
                        <h1>User Profile</h1>
                        <img className="user_image" src={userData.profileImageUrl} alt="User Avatar" />
                        <p className="user_username">@{userData.username}</p>
                        <p>First Name: {userData.firstName}</p>
                        <p>Last Name: {userData.lastName}</p>
                        <p>Middle Name: {userData.middleName}</p>
                        <p>Date of Birth: {userData.dateOfBirth}</p>
                        {showPasswordInput && (
                        <div className="user_change_password" >
                            <div className="createuser_input_item">
                                <label className="createuser_label" htmlFor="passsword">Old Password:</label><br/>
                                <input type="text" ref={oldPasswordRef} className="createuser_input" required />
                                  {invalidPassword && <p className="user_warning">Wrong Password. Please, check and try again</p>}            
                            </div> 
                            <div className="createuser_input_item">
                                <label className="createuser_label" htmlFor="passsword">New Password:</label><br/>
                                <input type="text" ref={newPasswordRef} className="createuser_input" required />
                            </div> 
                            <div>
                                <button className="user_button_submit" onClick={handlePasswordChange}>Change</button>
                                <button className="user_button_hide" onClick={toggleShowPassword}>Hide</button>
                            </div>
                        </div>
                        )}        
                            <h3 className="user_actions_heading">Roles</h3>
                            <ul className="create_user_roles_list">
                                {roles?.map(role => {
                                    return <li className="create_user_roles_list_item" key={role}>{role}</li>;
                                })}
                            </ul>              
                            <h3 className="user_actions_heading">Actions</h3>
                            <div className="user_actions">
                                {(userData._id === currentUser._id || currentUser.roles.includes("System Administrator")) && 
                                    !showPasswordInput && 
                                    <button className="user_button_submit" onClick={toggleShowPassword}>Change Password</button>
                                }
                                {userData._id == currentUser._id || currentUser.roles.includes("System Administrator") ? (
                                    <button className="user_button_submit" onClick={()=>window.location.href = `/hr/edit-user/${userData._id}`}>Update User</button>
                                ) : null}
                                {currentUser.roles.includes("System Administrator") && userData.username !== currentUser.username ? (
                                    <button className="user_button_warning" onClick={deleteUser}>Delete User</button>
                                ) : null}
                                {userData.username == currentUser.username ? (
                                    <button className="user_button_warning" onClick={logOut}>Log Out</button>
                                ) : null}
                                <button className="user_button_regular" onClick={()=>window.location.href = "/hr"}>Go Back</button>
                            </div>
                    </div>
            </div>
        </div>                    
        )
    }
}