import React, { useEffect, useState } from "react"
import { useParams } from 'react-router-dom';
import "./Order.css"
import Loader from "../../components/UI/Loader"
import axios from "../../plugins/axios";
import OrderIcon from "../../assets/orderIcon.svg"
import {v4} from "uuid"

export default function Order(){
    const {id} = useParams()
    const [order, setOrder] = useState([])
    const [loading, setLoading] = useState(false) 
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    console.log("current user: " + currentUser.firstName)
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const response = await axios.get(`/order/${id}`);
                console.log(response.data)
                setOrder(response.data)
                
            } catch (error) {
                console.error('Error fetching order:', error);
            }finally{
                setLoading(false)
            }
        };
        fetchData();
    }, []);

    const changeState = async(newState) =>{
        try {
            setLoading(true)
            const formData = {
                orderId: order._id,
                newState: newState
            }
            await axios.put("order/changeState", formData);       
            window.location.href = '/order'     
        }catch (error) {
            console.error('Error fetching order:', error);
        }finally{
            setLoading(false)
        }
    }

    const getDate = (date) =>{
        const newDate = new Date(date);
        let minutes = String(newDate.getMinutes()).padStart(2, '0');
        const dateString = `${newDate.getHours()}:${minutes}, ${newDate.getDate()}/${newDate.getMonth()}/${newDate.getFullYear()}`
        return dateString
    }

    if(!currentUser){
        return <NotAuthenticated />
    }
    if(loading){
        return <Loader />
    }
    return(
        <div className="order">
            <div className="container">
                <div className="order_card">
                <h1>Order: {order.title}</h1>
                <div className="order_image_container">
                    <img src={OrderIcon} alt="permit icon" className="order_image"/>
                </div>
                <p className="order_state"><strong>{order.state}</strong></p>
                <p>Orderer: {order.orderer}</p>
                <p>Product: {order.productTitle}</p>
                <p>Quantity ordered: {order.quantity}</p>
                <p>Order Date: {getDate(order.date)}</p>
                <h3>Actions</h3>
                <div className="order_actions">
                    {/* <button onClick={signTestOrder} className="order_action_item_sign">Test Signature</button> */}
                     {/* Signatures -- Draft */}
                    {order.state === "Pending" ? (
                         <div>
                            <button onClick={()=>{changeState("Preparing")}} className="order_action_item_sign">Move to "Preparing"</button>
                        </div>
                         ) : null}

                        {/* Signatures -- Awaiting Approval */}
                         {order.state === "Preparing" ? (
                            <div>
                                <button onClick={()=>{changeState("Prepared")}} className="order_action_item_sign">Move to "Prepared"</button>
                                <button onClick={()=>{changeState("Pending")}} className="order_action_item_sign">Revert to "Pending"</button>
                             </div>
                         ) : null}

                        {/* Signatures -- Live */}
                        {order.state === "Prepared" ? (
                             <div>
                                <button onClick={()=>{changeState("Sent")}} className="order_action_item_sign">Move to "Sent"</button>
                                <button onClick={()=>{changeState("Pending")}} className="order_action_item_sign">Revert to "Pending"</button>
                             </div>
                         ) : null}
                         {order.state === "Sent" ? (
                             <div>
                                <button onClick={()=>{changeState("Delivered")}} className="order_action_item_sign">Delivered</button>
                                <button onClick={()=>{changeState("Pending")}} className="order_action_item_sign">Revert to "Pending"</button>
                             </div>
                         ) : null}
                         <a href="/order" className="order_action_item_back">Close</a>
                </div>
                </div>

                   
        </div>
    </div>                      
    )    
}