import { useState, useRef, useEffect } from "react"
import axios from "../../plugins/axios";
import { useTranslation } from "react-i18next";
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { mkConfig, generateCsv, download } from "export-to-csv";
import "./CreateUserPage.css"
import Loader from "../../components/UI/Loader"
import NotAuthenticated from "src/components/layout/NotAuthenticated";



export default function CreateUserPage(){
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false) 

    const usernameRef = useRef(null);
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const middleNameRef = useRef(null);
    const dateOfBirthRef = useRef('1990-01-01');
    const passwordRef = useRef(null);
    const userEmail = useRef(null);

    const currentUser = JSON.parse(localStorage.getItem("currentUser"));


    
    const [rolesList, setRolesList] = useState(['User', "System Administrator", 'PermitRequestor', 'PermitVerifier', 'PermitActivator', 'HR']);
    const [rolesSelected, setRolesSelected] = useState(['User']);


   

    const handleSubmit = async (event) => {
        event.preventDefault();


        const userData = {
        username: usernameRef.current.value,
        firstName: firstNameRef.current.value,
        lastName: lastNameRef.current.value,
        middleName: middleNameRef.current.value,
        dateOfBirth: dateOfBirthRef.current.value,
        password: passwordRef.current.value,
        roles: rolesSelected
        };
        console.log(userData)


        try {
        const response = await axios.post('/auth/registration', userData);
        console.log('User created successfully:', response.data);
        window.location.href = '/hr';

        
        const csvData = [
            {
              name: `${userData.lastName} ${userData.firstName} ${userData.middleName}`,
              date: userData.dateOfBirth,
              roles: userData.roles,
              username: userData.username,
              password: userData.password,
            },
          ];


        const csvConfig = mkConfig({ useKeysAsHeaders: true, filename: `${userData.lastName}_${userData.firstName}_${userData.middleName}`});
        const csv = generateCsv(csvConfig)(csvData);
        download(csvConfig)(csv);
            
        } catch (error) {
        console.error('Error creating user:', error);
        }
    };

    const addRole = (role) => {
        // Check if the role is already selected
        if (!rolesSelected.includes(role)) {
          // If the role is not already selected, add it to the rolesSelected array
          setRolesSelected(prevRoles => [...prevRoles, role]);
        }
      };
    
    const removeRole = (role) => {
    setRolesSelected(prevRoles => prevRoles.filter(prevRole => prevRole !== role));
    };

    useEffect(() => {
        function generatePassword() {
            const characters = 'abcdefghijkmnopqrstuvwxyz0123456789';
            const passwordLength = 6;
            let password = '';
            for (let i = 0; i < passwordLength; i++) {
              const randomIndex = Math.floor(Math.random() * characters.length);
              password += characters[randomIndex];
            } 
            return password;
        }
        // dateOfBirthRef.current.value = '1990-01-01';
        passwordRef.current.value = generatePassword()
      }, []);

      
    

    return(
        <div className="createuser_page">
                <div className="createuser_card">
                <h1 className="createuser_title">{t("createuser_title") || "Log In"}</h1>
                {loading ? <h2>{t("createuser_loading", "Loading")}</h2> : null}
                <form onSubmit={handleSubmit}>
                <div className="createuser_input_item">
                    <label className="createuser_label" htmlFor="firstName">{t("createuser_firstName", "First Name")}:</label><br/>
                    <input type="text" className="createuser_input" ref={firstNameRef} required />
                </div>
                <div className="createuser_input_item">
                    <label className="createuser_label" htmlFor="lastName">{t("createuser_lastName", "Last Name")}:</label><br/>
                    <input type="text" className="createuser_input" ref={lastNameRef} required />
                </div>
                <div className="createuser_input_item">
                    <label className="createuser_label" htmlFor="middleName">{t("createuser_middleName", "Middle Name")}:</label><br/>
                    <input type="text" ref={middleNameRef} className="createuser_input" required />
                </div>
                {/* <div className="createuser_input_item">
                    <input type="file" className="createuser_input create_user_input_image" onChange={(e)=>setImage(e.target.files[0])}/>
                </div> */}
                <div className="createuser_input_item">
                    <label className="createuser_label" htmlFor="dateOfBirth" >{t("createuser_dateOfBirth", "Date Of Birth")}:</label><br/>
                    <input type="date" ref={dateOfBirthRef} className="createuser_input" required />
                </div>
                <div className="createuser_input_item">
                    <label className="createuser_label" htmlFor="username">{t("createuser_username", "Username")}:</label><br/>
                    <input type="text" ref={usernameRef} className="createuser_input" required />
                </div>
                <div className="createuser_input_item">
                    <label className="createuser_label" htmlFor="passsword">{t("createuser_password", "Password")}:</label><br/>
                    <input type="text" ref={passwordRef} className="createuser_input" />
                </div>  
                <div className="createuser_input_item">
                    {rolesList?.length !== rolesSelected?.length && (
                    <div>
                        <h3 className="create_user_roles_title">Roles</h3>
                        <span className="create_user_roles_list_instruction">Click to add role to user.</span>
                    </div>
                    )}
                    <ul className="create_user_roles_list">
                        {rolesList.map(role=>{
                            if(!rolesSelected.includes(role)){
                                 return (
                                        <li className="create_user_roles_list_item" key={role} onClick={()=>addRole(role)}>{role}</li>
                                    )
                                }
                        })}
                    </ul>
                    {rolesSelected?.length > 0 && (<div>
                        <h3 className="create_user_roles_title">Selected Roles</h3>
                        <span className="create_user_roles_list_instruction">Click to delete role from user.</span>
                    </div>)}
                    <ul className="create_user_roles_list">
                        {rolesSelected.map(role=>{
                            if(role == "User"){
                                return(
                                    <li className="create_user_roles_list_item_nonselectable" key={role}>{role}</li>
                                )
                            }else{
                                return (
                                    <li className="create_user_roles_list_item" key={role} onClick={()=>removeRole(role)}>{role}</li>
                                )
                            }
                        })}
                    </ul>
                </div>    
                {/* <div className="createuser_input_item">
                    <label className="createuser_label" htmlFor="email">{t("createuser_email", "Email (Send credentials to)")}:</label><br/>
                    <input type="email" ref={userEmail} className="createuser_input" />
                </div>            */}
                <div className="create_user_controls">
                    <button className='create_user_submit' type="submit">{t("createuser_submit") || "Submit"}</button>
                    <a href="/hr" className="create_user_close">{t("create_permit_close", "Close")}</a>
                </div>
                </form>
            </div>
        </div>
    )
}