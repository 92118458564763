import {Link } from "react-router-dom"
import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next";
import axios from "../../plugins/axios";
import Loader from "../../components/UI/Loader"
import NotAuthenticated from "src/components/layout/NotAuthenticated";
import PermitPageItem from "./PermitPageItem"
import "./PermitPage.css"




export default function PermitPage(){
    const { t } = useTranslation();


    const [selectedState, setSelectedState] = useState('Draft')
    const [permits, setPermits] = useState(null)
    const [loading, setLoading] = useState(false)
    const [draftItems, setDraftItems] = useState([]);
    const [awaitingItems, setAwaitingItems] = useState([]);
    const [liveItems, setLiveItems] = useState([]);
    const [suspendedItems, setSuspendedItems] = useState([]);
    const [closedItems, setClosedItems] = useState([]);
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    


    useEffect(()=>{
        const fetchData = async () =>{
            try{
                setLoading(true);
                const response = await axios.get('/permit') 
                setPermits(response.data)
                setDraftItems(response.data.filter(item => item.state === 'Draft'))
                setAwaitingItems(response.data.filter(item=>item.state === "Awaiting Approval"))
                setLiveItems(response.data.filter(item=>item.state === "Live"))
                setSuspendedItems(response.data.filter(item=>item.state === "Suspended"))
                setClosedItems(response.data.filter(item=>item.state === "Closed"))
            }
            catch(err){
                console.log(err);
                alert(err)
            }finally{
                setLoading(false)
            }   
        }
        fetchData()
    }, [])

    if(!currentUser){
        return <NotAuthenticated />
    }
    if(loading){
        return <Loader />
    }
    return(
        <div className="permitPage">
                <div className="container">
                    <div className="permit-header">
                        <h1>{t("permit_title", "Permit Management")}</h1>
                        <Link to="create" className="permit_page_create">{t("permit_create", "Create")}</Link>
                    </div>
                    <div className="permit_navigation">
                        <ul className="permit_state_list">
                            {selectedState==='Draft' ? (<li className="permit_page_state_selected">{t("permit_draft", "Draft")}</li>) : (<li className="permit_page_state" onClick={()=>{setSelectedState('Draft')}}>{t("permit_draft", "Open")}</li>) }
                            {selectedState==='Awaiting Approval' ? (<li className="permit_page_state_selected">{t("permit_awaiting", "Awaiting Approval")}</li>) : (<li className="permit_page_state" onClick={()=>{setSelectedState('Awaiting Approval')}}>{t("permit_awaiting", "Awaiting Approval")}</li>) }
                            {selectedState==='Live' ? (<li className="permit_page_state_selected">{t("permit_live", "Live")}</li>) : (<li className="permit_page_state" onClick={()=>{setSelectedState('Live')}}>{t("permit_live", "Live")}</li>) }
                            {selectedState==='Suspended' ? (<li className="permit_page_state_selected">{t("permit_suspended", "Suspended")}</li>) : (<li className="permit_page_state" onClick={()=>{setSelectedState('Suspended')}}>{t("permit_suspended", "Suspended")}</li>) }                         
                            {selectedState==='Closed' ? (<li className="permit_page_state_selected">{t("permit_closed", "Closed")}</li>) : (<li className="permit_page_state" onClick={()=>{setSelectedState('Closed')}}>{t("permit_closed", "Closed")}</li>) }                         
                        </ul>
                    </div>
                    <div className="permit_page_search_wrapper">
                        <div>
                            <div className="permit_page_search_title">
                                {/* <h4>Search:</h4> */}
                                <a href="/permit/search" className="permit_search_link">{t("permit_search", "Search")}</a>
                            </div>
                        </div>
                    </div>
                    {selectedState === "Draft" && !loading ? 
                    draftItems.map((permit=>{
                        return (
                            <PermitPageItem permit={permit} key={permit._id} />
                        )
                        })) 
                        : null}
                    {selectedState === "Draft" && !loading && draftItems.length === 0 ? (
                        <h2 data-aos="fade-up">{t("permit_nopermits", "No Permits")}</h2>
                    ) : null}

                    {selectedState === "Awaiting Approval" && !loading ? 
                    awaitingItems.map((permit=>{
                        return (
                            <PermitPageItem permit={permit} key={permit._id} />
                        )
                        })) 
                        : null}
                    {selectedState === "Awaiting Approval" && !loading && awaitingItems.length === 0 ? (
                        <h2 data-aos="fade-up">{t("permit_nopermits", "No Permits")}</h2>
                    ) : null}

                    {selectedState === "Live" && !loading ? 
                    liveItems.map((permit=>{
                        return (
                            <PermitPageItem permit={permit} key={permit._id} />
                        )
                        })) 
                        : null}
                    {selectedState === "Live" && !loading && liveItems.length === 0 ? (
                        <h2 data-aos="fade-up">{t("permit_nopermits", "No Permits")}</h2>
                    ) : null}
                    
                    {selectedState === "Suspended" && !loading ? 
                    suspendedItems.map((permit=>{
                        return (
                            <PermitPageItem permit={permit} key={permit._id} />
                        )
                        })) 
                        : null}
                    {selectedState === "Suspended" && !loading && suspendedItems.length === 0 ? (
                        <h2 data-aos="fade-up">{t("permit_nopermits", "No Permits")}</h2>
                    ) : null}


                    {selectedState === "Closed" && !loading ? 
                    closedItems.map((permit=>{
                        return (
                            <PermitPageItem permit={permit} key={permit._id} />
                        )
                        })) 
                        : null}
                    {selectedState === "Closed" && !loading && closedItems.length === 0 ? (
                        <h2 data-aos="fade-up">{t("permit_nopermits", "No Permits")}</h2>
                    ) : null}
                </div>
        </div>
    )
}