import { Link, useHistory } from 'react-router-dom';
import { useState, useEffect } from "react"
import React, { useRef } from 'react';
import Loader from "../../components/UI/Loader"
import {v4} from "uuid"
import axios from "../../plugins/axios";
import { useTranslation } from "react-i18next";
import "./WarehouseCreate.css"


export default function PermitCreate(){
    const { t } = useTranslation();
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const [loading, setLoading] = useState(false);
    
    const titleRef = useRef(null);
    const quantityRef = useRef(null);
    const measurementUnitRef = useRef(null);   
   

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const formData = {
            title: titleRef.current.value,
            quantity: quantityRef.current.value,
            measurementUnit: measurementUnitRef.current.value,
        };

        try {
            const response = await axios.post('/product/create', formData);
            alert('Data submitted successfully:', response.data);
            window.location.href = '/warehouse';
        } catch (error) {
            console.error('Error:', error);
        }
    };

    if(loading){
        return(
            <div className="loader_wrapper" data-aos="fade-up">
                <Loader />
            </div>
        )
    }
    return(
        <div className="permitPage">
            <div className="container">
                <form className="warehouse_create_card">
                    <h1 className="warehouse_create_title">{t("warehouse_create_heading", "Create Product")}</h1>
                    <div className="warehouse_create_input_item">
                        <label className="warehouse_create_label" htmlFor="title">{t("warehouse_create_title", "Title")}:</label><br/>
                        <input
                            className="warehouse_create_input"
                            type="text"
                            id="title"
                            ref={titleRef}
                        />
                    </div>

                    <div className="warehouse_create_input_item">
                        <label className="warehouse_create_label" htmlFor="quantity">{t("warehouse_create_quantity", "Quantity")}:</label><br/>
                        <input
                            type="number"
                            className="warehouse_create_input"
                            id="quantity"
                            ref={quantityRef}
                        />
                    </div>

                    <div className="warehouse_create_input_item">
                        <label className="warehouse_create_label" htmlFor="measurementUnit">{t("warehouse_create_location", "Measurement Unit")}:</label><br/>
                        <input
                            className="warehouse_create_input"
                            type="text"
                            id="measurementUnit"
                            ref={measurementUnitRef}
                        />
                    </div>

                    <div className="warehouse_create_controls">
                        <button onClick={handleFormSubmit} className="warehouse_create_submit">{t("warehouse_create_submit", "Submit")}</button>
                        <a href="/warehouse" className="warehouse_create_close">{t("warehouse_create_close", "Close")}</a>
                    </div>            
                </form>
            </div>
        </div>
    )
}