import "./PermitPageItem.css"
import { useTranslation } from "react-i18next";


import MechanicalPermit from "../../assets/mechanical_permit.svg"
import ElectricalPermit from "../../assets/electrical_permit.svg"
import HotWorkPermit from "../../assets/hotwork_permit.svg"
import LiftingPermit from "../../assets/lifting_permit.svg"
import CleaningPermit from "../../assets/cleaning_permit.svg"



export default function PermitPageItem(props){
    const { t } = useTranslation();
    
    return (
        <div className="permit_list_item" onClick={()=>{window.location.href = `/permit/${props.permit._id}`}}>
            <div className="permit_list_item_image_wrapper">
                {props.permit.type == "Mechanical" ? <img src={MechanicalPermit} alt="permit icon" /> : null}
                {props.permit.type == "Electrical" ? <img src={ElectricalPermit} alt="permit icon" /> : null}
                {props.permit.type == "Hot Work" ? <img src={HotWorkPermit} alt="permit icon" /> : null}
                {props.permit.type == "Weigh Lifting" ? <img src={LiftingPermit} alt="permit icon" /> : null}
                {props.permit.type == "Cleaning" ? <img src={CleaningPermit} alt="permit icon" /> : null}
            </div>
            <div>
                <h3>{props.permit.title}</h3>
                <p>{t("permit_item_description", "Description")}: {props.permit.description}</p>
                <p>{t("permit_item_state", "State")}: {props.permit.state}</p>
                <p>{t("permit_item_planneddate", "Planned Date")}: {props.permit.startDate + " - " + props.permit.endDate}</p>
            </div>
        </div>
    )
}