import {Link} from 'react-router-dom'
import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import userIcon from "../../assets/icon_user.png"



import "./Navigation.css"

export default function Navigation(){
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    const [selectedLink, setSelectedLink] = useState(window.location.pathname)

    const currentLang = localStorage.getItem("lng");
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    // const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    
    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        localStorage.setItem("lng", lang);
      };
    
    const changePage = function(page){
        localStorage.setItem('currentPage', page);
    }


    return(
        <nav className="navigation_component">
                <div className="navigation_wrapper">
                    <div className="navigation_flex">
                    <div className="navigation_logo">
                        <h2 className='navigation_title'>FactoryPro</h2>
                        <ul className='navigation_links'>
                            {selectedLink == "/" ? 
                                <li><a href="/" className='navigation_link_selected' onClick={()=>{changePage("Home")}}>{t("nav_home", "Home")}</a></li> : 
                                <li><a href="/" className='navigation_link' onClick={()=>{changePage("Home")}}>{t("nav_home", "Home")}</a></li>
                            }
                            {selectedLink == "/permit" ? 
                                <li><a href="/permit" className='navigation_link_selected' onClick={()=>{changePage("PTW")}}>{t("nav_permit", "Permit to Work")}</a></li> : 
                                <li><a href="/permit" className='navigation_link' onClick={()=>{changePage("PTW")}}>{t("nav_permit", "Permit to Work")}</a></li>
                            }
                            {selectedLink == "/order" ? 
                                <li><a href="/order" className='navigation_link_selected' onClick={()=>{changePage("Orders")}}>{t("nav_orders", "Orders")}</a></li> : 
                                <li><a href="/order" className='navigation_link' onClick={()=>{changePage("Orders")}}>{t("nav_orders", "Orders")}</a></li>
                            }
                            {selectedLink == "/warehouse" ? 
                                <li><a href="/warehouse" className='navigation_link_selected' onClick={()=>{changePage("Warehouse")}}>{t("nav_warehouse", "Warehouse")}</a></li> : 
                                <li><a href="/warehouse" className='navigation_link' onClick={()=>{changePage("Warehouse")}}>{t("nav_warehouse", "Warehouse")}</a></li>
                            }
                            {selectedLink == "/hr" ? 
                                <li><a href="/hr" className='navigation_link_selected' onClick={()=>{changePage("HumanResource")}}>{t("nav_hr", "Human Resource")}</a></li> : 
                                <li><a href="/hr" className='navigation_link' onClick={()=>{changePage("HumanResource")}}>{t("nav_hr", "Human Resource")}</a></li>
                            }
                            {/* {selectedLink == "/profile" ? 
                                <li><a href="/profile" className='navigation_link_selected' onClick={()=>{changePage("Profile")}}>{t("nav_profile", "Profile")}</a></li> : 
                                <li><a href="/profile" className='navigation_link' onClick={()=>{changePage("Profile")}}>{t("nav_profile", "Profile")}</a></li>
                            } */}
                        </ul>
                    </div>
                    <div className="navigation_controls">
                            {currentLang === 'en' ? null : <button onClick={() => changeLanguage("en")} className="navigation_lang">EN</button>}
                            {currentLang === 'ru' ? null : <button onClick={() => changeLanguage("ru")} className="navigation_lang">RU</button>}
                            {currentLang === 'uz' ? null : <button onClick={() => changeLanguage("uz")} className="navigation_lang">UZ</button>}
                            {currentUser && <a href={`/hr/user/${currentUser.username}`} className='navigation_profile_link'>@{currentUser.username}</a>}
                            {!currentUser && <a href="/login" className='navigation_profile_link'>{t("login_title", "Log in")}</a>}
                            {/* {currentUser ? <Link to='account' className='navigation_user'>{currentUser.username}</Link> : null} */}
                            
                    </div>
                </div>
            </div>
            
        </nav>
    )
}