import React, { useState, useEffect } from 'react';
import {imageDb} from "../../firebase/firebase"
import "./Testing.css"
import { getDownloadURL, listAll, ref, uploadBytes} from '@firebase/storage';
import { v4 } from 'uuid';

export default function Testing(){
    const [date, setDate] = useState('')

    const [image, setImage] = useState('')
    const [imageTitle, setImageTitle] = useState('nomonjon')
    const [imageUrl, setImageUrl] = useState([])

    const handleSubmit = ()=>{
    //    setImageTitle(v4())
       console.log(imageTitle)
       const imageRef = ref(imageDb, `profilePhotos/${imageTitle}`)
       uploadBytes(imageRef, image)
    }


    useEffect(()=>{
        listAll(ref(imageDb, "profilePhotos")).then(imgs=>{
            imgs.items.forEach(val=>{
                getDownloadURL(val).then(url=>{
                    setImageUrl(data=>[...data,url])
                })
            })
        })
    },[])
    console.log(imageUrl, "imageUrl")
    console.log(date)
    console.log(typeof(date))
    return(
        <>
            <div className="container">
                <h1>Testing</h1>
                <input type="file" onChange={(e)=>setImage(e.target.files[0])}/>
                <input type="date" onChange={(e)=>setDate(e.target.value)}/>

                <button onClick={handleSubmit}>Send</button>
            </div>
        </>
    )
}