import {Link } from "react-router-dom"
import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next";
import axios from "../../plugins/axios";
import Loader from "../../components/UI/Loader"
import PermitPageItem from "./PermitPageItem"
import NotAuthenticated from "src/components/layout/NotAuthenticated";
import "./PermitSearch.css"




export default function PermitSearch(){
    const { t } = useTranslation();
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const [permits, setPermits] = useState([])
    const [filteredPermits, serFilteredPermits] = useState([])
    const [searchQuery, setSearchQuery] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        const fetchData = async () =>{
            try{
                setLoading(true);
                const response = await axios.get('/permit') 
                setPermits(response.data)
            }
            catch(err){
                console.log(err);
            }finally{
                setLoading(false)
            }   
        }
        fetchData()
    }, [])

    useEffect(() => {
        // Filter users based on searchQuery
        const filtered = permits.filter(permit =>
            permit.title.toLowerCase().includes(searchQuery.toLowerCase())
        );
        serFilteredPermits(filtered);
    }, [searchQuery, permits]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };


    console.log(permits)
    if(!currentUser){
        return <NotAuthenticated />
    }
    if(loading){
        return <Loader />
    }
    
    return(
        <div className="permitPage">
                <div className="container">
                    <div className="permit-header">
                        <h1>{t("permit_search_title", "Permit Search")}</h1>
                        <Link to="create" className="permit_page_create">{t("permit_search_create", "Create")}</Link>
                    </div>
                    <div className="permit_search_input_wrapper">
                        <button className="permit_search_button_regular" onClick={()=>window.location.href='/permit'}>↩ {t("permit_search_goback", "Go Back")}</button>
                        <input type="text" placeholder={t("permit_search", "Search")} className="permit_search_input" value={searchQuery} onChange={handleSearchChange} />
                    </div>
                    {filteredPermits?.length > 0 && filteredPermits.map(permit=>
                        <PermitPageItem permit={permit} key={permit._id} />
                    )}
                    {filteredPermits?.length == 0 && permits.map(permit=>
                        <PermitPageItem permit={permit} key={permit._id} />
                    )}
                </div>
        </div>
    )
}