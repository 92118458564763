import { useState } from "react"
import axios from "../../plugins/axios";
import { useTranslation } from "react-i18next";
import './SignupPage.css'
import { Link } from "react-router-dom";

export default function Signup(){
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        username:  '',
        firstName: '',
        lastName: '',
        password: '',
    })
    const [usernameIsValid, setUsernameIsValid] = useState(true);
    const [passwordIsValid, setPasswordIsValid] = useState(true);
    const [loading, setLoading] = useState(false);

    function isUsernameValid(username) {
        const regex = /^[a-z]{4,}$/;
        return regex.test(username);
    }

    function isPasswordValid(password) {
        const regex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]{8,}$/;
        return regex.test(password);
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUsernameIsValid(isUsernameValid(formData.username));
        setPasswordIsValid(isPasswordValid(formData.password));
        setFormData({ ...formData, [name]: value });
      };

    const handleFormSubmit = async(e) => {
        e.preventDefault();
        if(usernameIsValid && passwordIsValid){
            try {
                setLoading(true);
                console.log(formData)
                const res = await axios.post("auth/registration", formData)
                localStorage.setItem("currentUser", JSON.stringify(res.data.user));
                setLoading(false)
                window.location.href = '/home';
              } catch (err) {
                console.log(err);
                alert(t("signup_error", "Check your credentials and try again."));
                return false;
              }
        }
    }
    return(
        <div className="signup_page">
                <div className="signup_card">
                <h1 className="signup_title">{t("signup_title") || "Log In"}</h1>
                {loading ? <h2>{t("signup_loading", "Loading")}</h2> : null}
                <form onSubmit={handleFormSubmit}>
                <div className="signup_input_item">
                    <label className="signup_label" htmlFor="firstName">{t("signup_firstName", "First Name")}:</label><br/>
                    <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    />
                </div>
                <div className="signup_input_item">
                    <label className="signup_label" htmlFor="lastName">{t("signup_lastName", "Last Name")}:</label><br/>
                    <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    />
                </div>
                <div className="signup_input_item">
                    <label className="signup_label" htmlFor="username">{t("signup_username", "Username")}:</label><br/>
                    <input
                    type="text"
                    id="username"
                    name="username"
                    value={formData.username}
                    onChange={handleInputChange}
                    />
                    {usernameIsValid || formData.username === '' ? null : <p className="signup_warning">{t("signup_username_validation", "Username should contain letters a-z, numbers 0-9 with no spaces")}</p>}            
                </div>
                <div className="signup_input_item">
                    <label className="signup_label" htmlFor="password">{t("signup_password","Password")}:</label><br/>
                    <input
                    type="password"
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    />
                    {passwordIsValid || formData.password === '' ? null : <p className="signup_warning">{t("signup_password_validation", "Password should contain letters a-z, numbers 0-9, symbols '@,!,#,$,%' with no spaces")}</p>}
                </div>
                <button className='signup_submit' type="submit">{t("signup_submit") || "Submit"}</button>
                <p className="signup_redirect">{t("signup_redirect_text", "Already have account?")} <Link to='/login'>{t("signup_redirect_link", "Log In")}</Link></p>
                </form>
            </div>
        </div>
    )
}